var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "primary w-100 h-100"
  }, [_c('v-card', {
    staticClass: "w-xs-100",
    attrs: {
      "flat": "",
      "color": "primary center"
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-center font-bold text-h4"
  }, [_vm._v(" TUTTI ")]), _c('v-card-title', {
    staticClass: "justify-center my-4 text-subtitle-1 text-sm-h6"
  }, [_vm._v(" Great to have you on board " + _vm._s(_vm.$user.firstName) + "! ")]), _c('v-card-subtitle', {
    staticClass: "text-center text-subtitle-2 text-sm-subtitle-1"
  }, [_vm._v(" You will be redirected on Promotor Dashboard in a second! ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }