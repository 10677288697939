<template>
  <div class="primary w-100 h-100">
    <v-card class="w-xs-100" flat color="primary center">
      <v-card-title class="d-flex justify-center font-bold text-h4"> TUTTI </v-card-title>

      <v-card-title class="justify-center my-4 text-subtitle-1 text-sm-h6">
        Great to have you on board {{ $user.firstName }}!
      </v-card-title>

      <v-card-subtitle class="text-center text-subtitle-2 text-sm-subtitle-1">
        You will be redirected on Promotor Dashboard in a second!
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$router.push({ name: 'admin.dashboard' });
    }, 5000);
  },
};
</script>
